import * as CookieConsent from 'vanilla-cookieconsent';

const CAT_NECESSARY = 'necessary';
const CAT_ANALYTICS = 'analytics';

const SERVICE_ANALYTICS_STORAGE = 'analytics_storage';

// Define dataLayer and the gtag function.
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

// Set default consent to 'denied' (this should happen before changing any other dataLayer)
gtag('consent', 'default', {
  [SERVICE_ANALYTICS_STORAGE]: 'denied'
});

/**
 * Update gtag consent according to the users choices made in CookieConsent UI
 */
function updateGtagConsent() {
  gtag('consent', 'update', {
    [SERVICE_ANALYTICS_STORAGE]: CookieConsent.acceptedService(
      SERVICE_ANALYTICS_STORAGE,
      CAT_ANALYTICS
    )
      ? 'granted'
      : 'denied'
  });
}

CookieConsent.run({
  onFirstConsent: () => {
    updateGtagConsent();
  },
  onConsent: () => {
    updateGtagConsent();
  },
  onChange: () => {
    updateGtagConsent();
  },

  // Configure categories and services
  categories: {
    [CAT_NECESSARY]: {
      enabled: true, // this category is enabled by default
      readOnly: true // this category cannot be disabled
    },
    [CAT_ANALYTICS]: {
      autoClear: {
        cookies: [
          {
            name: /^_ga/ // regex: match all cookies starting with '_ga'
          },
          {
            name: '_gid' // string: exact cookie name
          }
        ]
      },
      services: {
        [SERVICE_ANALYTICS_STORAGE]: {
          label:
            'Enables storage (such as cookies) related to analytics e.g. visit duration.'
        }
      }
    }
  },

  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom left',
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: true,
      flipButtons: false
    }
  },

  language: {
    default: 'en',
    autoDetect: 'browser',
    translations: {
      en: {
        consentModal: {
          title: 'We Value Your Privacy',
          description:
            'By clicking "Accept All", you agree to the storing of cookies on your device to enhance site navigation and analyze site usage.',
          acceptAllBtn: 'Accept All',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage Preferences',
          footer:
            '<a href="/privacy">Privacy Policy</a>\n<a href="/terms">Terms and Conditions</a>'
        },
        preferencesModal: {
          title: 'Consent Preferences Center',
          acceptAllBtn: 'Accept All',
          acceptNecessaryBtn: 'Reject All',
          savePreferencesBtn: 'Save Preferences',
          closeIconLabel: 'Close modal',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Cookie Usage',
              description:
                'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about your preferences or your device and is mostly used to make the site work as you expect it to. The information does not directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies.'
            },
            {
              title:
                'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
              description:
                'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not work properly. These cookies do not store any personally identifiable information.',
              linkedCategory: CAT_NECESSARY
            },
            {
              title: 'Analytics Cookies',
              description:
                'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.',
              linkedCategory: CAT_ANALYTICS,
              cookieTable: {
                headers: {
                  name: 'Name',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration'
                },
                body: [
                  {
                    name: '_ga',
                    domain: 'Google Analytics',
                    description:
                      'Cookie set by <a href="https://business.safety.google/adscookies/">Google Analytics</a>',
                    expiration: 'Expires after 12 days'
                  },
                  {
                    name: '_gid',
                    domain: 'Google Analytics',
                    description:
                      'Cookie set by <a href="https://business.safety.google/adscookies/">Google Analytics</a>',
                    expiration: 'Session'
                  }
                ]
              }
            },
            {
              title: 'More information',
              description:
                'More information about cookies can be found on our <a class="cc__link" href="/cookies">Cookies Policy</a> page.'
            }
          ]
        }
      }
    }
  }
});
