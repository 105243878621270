import Alpine from 'alpinejs';
import Clipboard from '@ryangjchandler/alpine-clipboard';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist';
import Cookies from 'js-cookie';

Alpine.magic('scrollHome', () => (id) => {
  const el = document.getElementById(id);
  if (el) {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    window.scrollTo({
      top: Math.abs(el.getBoundingClientRect().top) - 100,
      left: 0,
      behavior: 'instant'
    });
  }
});

Alpine.magic('codeNotify', () => (url) => {
  const el = document.getElementById('language');
  if (el) {
    el.dataset.url = 'https://api.checkwx.com' + url;
    el.dispatchEvent(new Event('change'));
  }
});

Alpine.plugin(intersect);
Alpine.plugin(focus);
Alpine.plugin(persist);
Alpine.plugin(Clipboard);

window.Alpine = Alpine;

Alpine.data('toasty', () => ({
  showAlert: false,
  alert: '',
  msg: '',
  dismiss: false,
  init() {
    const alert = Cookies.get('alert');
    if (alert) {
      this.showAlert = true;
      this.alert = alert;
      this.msg = Cookies.get('alertM');
      this.dismiss = Cookies.get('alertD') || false;
      Cookies.remove('alert');
      if (this.dismiss && this.dismiss == '1') {
        setTimeout(() => (this.showAlert = false), 5000);
      }
    }
  }
}));

Alpine.data('fetchApiKey', (userid) => ({
  key: '',
  getData() {
    fetch(`/maui/user/key/${userid}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.api);
        this.key = data.api;
      });
  }
}));

Alpine.start();

if (document.querySelector('.home-page')) {
  import('./home.js').then(function (page) {
    page.render();
  });
}

if (document.querySelector('.docs-page')) {
  import('./docs.js').then(function (page) {
    page.render();
  });
}

if (document.querySelector('.code-page')) {
  import('./sourcecode.js').then(function (page) {
    page.render();
  });
}

if (document.querySelector('.dashboard-page')) {
  import('./dashboard.js').then(function (page) {
    page.render(mapOpts);
  });
}

if (document.querySelector('.forms-page')) {
  import('./forms.js').then(function (page) {
    page.render();
  });
}

if (document.querySelector('.search-page')) {
  import('./search.js').then(function (page) {
    page.render();
  });
}

if (document.querySelector('.maui-page')) {
  import('./maui.js').then(function (page) {
    page.render();
  });
}

document.addEventListener('DOMContentLoaded', function () {
  const el = document.getElementById('main-content');
  if (el) {
    el.focus();
  }
});
